import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const ServicesSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [servicesSectionData, setServicesSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let name_hotel;
                    const servicesSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-services-section-data/' + company_id + '/' + template_id
                    );
                    const result = await servicesSection.json();

                    if (result && result.hotel_name) {
                        name_hotel = result.hotel_name.hotel_name.replace(/[^a-zA-Z0-9 ]/g, "");
                        name_hotel = name_hotel.replace(/ +/g, "_");
                        name_hotel = name_hotel.toLowerCase();
                    }

                    setServicesSectionData({ "selected_services": result && result.selected_services, "section_details": result && result.section_details, "hotel_name": name_hotel });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = servicesSectionData && servicesSectionData.section_details

    return (
        <div>

            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


            {servicesSectionData && servicesSectionData.selected_services && servicesSectionData.selected_services.length > 0 && <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top services-margin-top">
                    <div className="row service-row">
                        {servicesSectionData.selected_services.map(function (item, i) {
                            return (
                                <div className="col-md-3 text-center" key={i}>
                                    <div className="service-icon">
                                        <i className={item.icon} ></i>
                                    </div>
                                    <div className="service-heading">{item.title}</div>
                                    <div className="services-descrp-text" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>}

        </div>

    );
}


export default ServicesSection;