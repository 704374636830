import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const ContactSection = (props) => {

    const [companyDetails] = useContext(CompanyContext);
    const [contactSectionData, setContactSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const contactSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-contact-section-data/' + company_id + '/' + template_id
                    );
                    const result = await contactSection.json();

                    setContactSectionData({ "contact_details": result && result.contact_details, "section_details": result && result.section_details, "all_hotels": result && result.all_hotels_data });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = contactSectionData && contactSectionData.section_details

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [validname, setvalidname] = useState(true);
    const [validemail, setvalidemail] = useState(true);
    const [validnumber, setvalidnumber] = useState(true);
    const [message, setmessage] = useState('');
    const [subject, setSubject] = useState('');
    const [successmsg, setsuccessmsg] = useState(true)

    let social_media_links = contactSectionData && contactSectionData.all_hotels;

    let display_section_title_des = props && props.data

    const contactform = () => {

        if (validname === true && validemail === true && validnumber === true && name.length !== 0 && email.length !== 0 && number.length !== 0) {
            fetch(config.apiEndpoint + '/userwb/send-user-query',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": contactSectionData && contactSectionData.all_hotels[0].hotel_id,
                        "name": name,
                        "email": email,
                        "mobile": number,
                        "message": message

                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(false)
                })

            fetch(config.apiEndpoint + '/insert-customer-data',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": contactSectionData && contactSectionData.all_hotels[0].hotel_id,
                        "user_name": name,
                        "user_email_id": email,
                        "user_contact_number": number,
                        "user_message": message
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    // setsuccessmsg(true)
                })

        }
        else {
            if (name.length === 0 && email.length === 0 && number.length === 0) {
                setvalidname(false);
                setvalidemail(false);
                setvalidnumber(false);
            }

        }
    }


    const validateName = (name) => {
        setname(name)
        if (name.match(/[A-Za-z]{2,}/)) {
            setvalidname(true)
        }
        else {
            setvalidname(false)

        }

    }

    const validateSubject = (subject) => {
        setSubject(subject)
    }

    const validateEmail = (email) => {
        setemail(email)

        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            setvalidemail(true)
        }
        else {
            setvalidemail(false)
        }

    }
    const validateNumber = (number) => {
        setnumber(number)
        if (number.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
            setvalidnumber(true)
        }
        else {
            setvalidnumber(false)

        }
    }
    const validateMessage = (message) => {
        setmessage(message)
    }

    return (

        <div>
            {section_details &&  !display_section_title_des && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


            <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top">
                    <div className="row">
                        <div className="col-md-6 col-margin">
                            {contactSectionData && contactSectionData.contact_details && contactSectionData.contact_details.map(function (item, id) {

                                return (
                                    <div className="contact-detail-wrap" key={id}>
                                        <div className="contact-head">{item.address_name}</div>
                                        {item.description && <div className="contact-details" dangerouslySetInnerHTML={{ __html: item.description }}></div>}


                                        { item.mobile_no && <div className="contact-details"><i className="fa fa-mobile fa-lg fa-green"></i> Mobile:- <a href={"tel:" + item.mobile_no}><span className="contact-color">{item.mobile_no}</span></a></div>}

                                        { item.landline_no && <div className="contact-details"><i className="fa fa-phone-square fa-green"></i> Landline:- <a href={"tel:" + item.landline_no}><span className="contact-color">{item.landline_no}</span></a></div>}

                                        { item.whatsapp_no && <div className="contact-details"><i className="fa fa-whatsapp fa-green"></i> WhatsApp:- <a href={"https://api.whatsapp.com/send?phone=" + item.whatsapp_no}><span className="contact-color">{item.whatsapp_no}</span></a></div>}

                                        {item.email && item.email.length > 0 && item.email[0] && item.email.map(function (items, idx) {
                                            return (
                                                <div className="contact-details" key={idx}><i className="fa fa-envelope fa-green"></i> Email:- <a href={"mailto:" + items}><span className="contact-color">{items}</span></a></div>
                                            )
                                        })}


                                    </div>
                                )
                            })}



                            <div className="contact-social-nav">
                                <ul className="contact-social-links">

                                    {social_media_links && social_media_links.facebook_link && <li><a href={social_media_links.facebook_link} target="_blank"><i className="fa fa-facebook"></i></a></li>}

                                    {social_media_links && social_media_links.twitter_link && <li><a href={social_media_links.twitter_link} target="_blank"><i className="fa fa-twitter"></i></a></li>}

                                    {social_media_links && social_media_links.linked_in_link && <li><a href={social_media_links.linked_in_link} target="_blank"><i className="fa fa-linkedin"></i></a></li>}

                                    {social_media_links && social_media_links.instagram_link && <li><a href={social_media_links.instagram_link} target="_blank"><i className="fa fa-instagram"></i></a></li>}

                                </ul>
                            </div>

                        </div>


                        <div className="col-md-6 col-margin">
                            <div className="row">
                                <div className="col-md-6">
                                    <div hidden={validname} style={{ color: 'red' }}>Invalid Name</div>
                                    <input className="input-style" placeholder="Full Name *" type="text" value={name} onChange={(e) => validateName(e.target.value)} />
                                </div>

                                <div className="col-md-6">
                                    <div hidden={validemail} style={{ color: 'red' }}>Invalid Email</div>
                                    <input className="input-style" placeholder="Your Email *" type="email" value={email} onChange={(e) => validateEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <input className="input-style" placeholder="Subject *" type="text" value={subject} onChange={(e) => validateSubject(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div hidden={validnumber} style={{ color: 'red' }}>Invalid Number</div>
                                    <input className="input-style" placeholder="Number *" type="text" value={number} onChange={(e) => validateNumber(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <textarea className="text-area-style contact-textarea" placeholder="Your Message *" row="10" value={message} onChange={(e) => validateMessage(e.target.value)}></textarea>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-btn" onClick={() => contactform()}>
                                        <a >Send Message</a>
                                    </div>
                                    <div hidden={successmsg} style={{ color: 'red', fontSize: 15, fontWeight: 'bold' }}>Submitted Successfully !</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>


        </div>
    );
}

export default ContactSection;