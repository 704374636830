import React, { useState, useEffect, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import Slider from './Slider'
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import { Link } from 'react-router-dom'
import _debounce from 'lodash.debounce'
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import OfferSection from './sections/OfferSection'
import AboutSection from './sections/AboutSection'
import ServiceSection from './sections/ServiceSection'
import PackageSection from './sections/PackageSection';
import RoomSection from './sections/RoomSection';
import GallerySection from './sections/GallerySection';
import TestimonialSection from './sections/TestimonialSection';
import FacebookSection from './sections/FacebookSection';
import BlogSection from './sections/BlogSection';
import EventSection from './sections/EventSection';
import NewsletterSection from './sections/NewsletterSection';
import ContactSection from './sections/ContactSection';
import NearbyPlacesSection from './sections/NearbyPlacesSection';
import FacilitiesSection from './sections/FacilitiesSection';
import PromotionalBannerSection from './sections/PromotionalBannerSection';
import PromotionSection from './sections/PromotionSection';
import AwardsSection from './sections/AwardsSection';
import BookingBenefitsSection from './sections/BookingBenefitsSection';
import CustomFooterSection from './sections/CustomFooterSection';
import CustomSection from './sections/CustomSection';


function Home() {
    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [homePageData, setHomePageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let display_default_footer=true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-home-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if(result && result.home_page_data && result.home_page_data.page_section){
                        for (let i = 0; i < result.home_page_data.page_section.length; i++) {
                            if (result.home_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer=false
                            }
                        }
                    }

                    setHomePageData({ "home_page_data": result && result.home_page_data, "page_section": result && result.home_page_data && result.home_page_data.page_section, "all_hotels_data": result && result.all_hotels,"display_default_footer":display_default_footer})

                    window.$('.carousel').carousel({
                        interval: 5000
                    });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    
    // const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font

    var scrolled = false;
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        scrolled = true;
    });
    

    return (

        <div>
            {(homePageData === undefined) ? <div className="loader" style={{ backgroundImage: "url(" + require('../img/preload.gif') + ")" }}></div> : <Header />}
            {homePageData && homePageData.home_page_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{homePageData.home_page_data.seo_title}</title>
                    <meta name="description" content={homePageData.home_page_data.seo_description} />
                </MetaTags>
            }

            {homePageData && <Slider />}
             

            {homePageData && homePageData.all_hotels_data && homePageData.all_hotels_data.length > 1 && <DatePickerForMultiHotels multi_hotel_data={homePageData.all_hotels_data} companyDetails={companyDetails}/>}

            {homePageData && homePageData.all_hotels_data && homePageData.all_hotels_data.length === 1 && <DatePicker hoteldata={homePageData.all_hotels_data} companyDetails={companyDetails}/>}
             
            {homePageData && homePageData.page_section && homePageData.page_section.map(function (item, i) {

                if (item.section_names === "Promotional Banner") {
                    return (
                        <PromotionalBannerSection key={i} />
                    )
                }

                if (item.section_names === "Newsletter") {
                    return (
                        <NewsletterSection key={i} />
                    )
                }

                if (item.section_names === "About Us") {
                    return (
                        <AboutSection key={i} />
                    )
                }

                if (item.section_names === "Rooms") {
                    return (
                        <RoomSection key={i} />
                    )
                }


                if (item.section_names === "Gallery") {
                    return (
                        <GallerySection key={i} />
                    )
                }

                if (item.section_names === "Contact Us") {
                    return (
                        <ContactSection key={i} />
                    )
                }

                if (item.section_names === "Services") {
                    return (
                        <ServiceSection key={i} />
                    )
                }


                if (item.section_names === "Nearby Places") {
                    return (
                        <NearbyPlacesSection key={i} />
                    )
                }

                if (item.section_names === "Testimonial") {
                    return (
                        <TestimonialSection key={i} />
                    )
                }


                if (item.section_names === "Facility") {
                    return (
                        <FacilitiesSection key={i} />
                    )
                }


                if (item.section_names === "Package") {
                    return (
                        <PackageSection key={i} />
                    )
                }

                if (item.section_names === "Blog") {
                    return (
                        <BlogSection key={i} />
                    )
                }

                if (item.section_names === "Event") {
                    return (
                        <EventSection key={i} />
                    )
                }

                if (item.section_names === "Offer") {
                    return (
                        <OfferSection key={i} />
                    )
                }


                if (item.section_names === "Facebook") {
                    return (
                        <FacebookSection key={i} />
                    )
                }

                if (item.section_names === "Promotions") {
                    return (
                        <PromotionSection key={i}/>

                    )
                }

                if (item.section_names === "Awards") {
                    return (
                        <AwardsSection key={i} />
                    )
                }


                if (item.section_names === "Booking Benfits") {
                    return (
                        <BookingBenefitsSection key={i} />
                    )
                }

                if (item.section_type === "custom") {
                    return (
                        <CustomSection key={i} section_id={item.id} name={item.section_names}/>
                    )
                }
                
                if (item.section_names === "Custom Footer") {
                    return (
                        <CustomFooterSection key={i}/>
                    )
                }
            })}

            {homePageData && homePageData.display_default_footer && homePageData.display_default_footer === true && <Footer />}

            <a id="" className="scrollup show" title="Go To Top" style={{ bottom: "15px" }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}></a>


        </div>

    );
}


function DatePicker(props) {
    let hotel_details = props.hoteldata;
    let companyDetails = props.companyDetails;
    const [screen_size, setScreensize] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });

    let hotelId = hotel_details && hotel_details[0].hotel_id

    const [focusedInput, setfocusedInput] = useState(null)
    const [fromdate, setFromDate] = useState(moment())
    const [todate, setToDate] = useState(moment(fromdate).add(1, 'days'))
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    let iframe
    let encryptData
    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotelId + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData

    }

    return (

        <section>
            <div className="container container-space">
                <div className="row">
                    <div className="datepicker-card">
                        <form>
                            <div className="row datepicker-row">

                                <div className="col-md-8 col-sm-12 col-height">
                                    <DateRangePicker
                                        numberOfMonths={2} orientation="horizontal"
                                        startDate={fromdate}
                                        startDateId="your_unique_start_date_id"
                                        endDate={todate}
                                        endDateId="your_unique_end_date_id"
                                        onDatesChange={({ startDate, endDate }) => {
                                            setFromDate(startDate)
                                            setToDate(endDate)
                                        }}
                                        focusedInput={focusedInput}
                                        onFocusChange={focusedInput => setfocusedInput(focusedInput)}
                                        displayFormat='DD-MM-YYYY'
                                        customArrowIcon="|"
                                        orientation={(screen_size > 767) ? "horizontal" : "vertical"}
                                        block={true}
                                    />
                                </div>

                                <div className="col-md-4 col-sm-12 col-height">
                                    {/* <Link className="standard-btn" to={{
                                        pathname: config.PATH + 'booknow',
                                        search: '?' + (iframe),
                                    }}>Check Availability</Link> */}
                                    <a className="standard-btn" href={"https://" + companyDetails.subdomain_name}>Check Availability</a>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    )
}


function DatePickerForMultiHotels(props) {
    let multi_hotel_details = props.multi_hotel_data;
    let companyDetails = props.companyDetails;
    const [screen_size, setScreensize] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });
    const [hotelId, setHotelId] = useState(multi_hotel_details && multi_hotel_details[0].hotel_id);
    const hotelName = (e) => {
        setHotelId(e);
    }

    const [focusedInput, setfocusedInput] = useState(null)
    const [fromdate, setFromDate] = useState(moment())
    const [todate, setToDate] = useState(moment(fromdate).add(1, 'days'))
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    let iframe
    let encryptData

    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotelId + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData

    }

    return (

        <section>
            <div className="container container-space">
                <div className="row">
                    <div className="datepicker-card">
                        <form>
                            <div className="row datepicker-row">

                                <div className="col-md-6 col-sm-12 col-height">
                                    <DateRangePicker
                                        numberOfMonths={2} orientation="horizontal"
                                        startDate={fromdate}
                                        startDateId="your_unique_start_date_id"
                                        endDate={todate}
                                        endDateId="your_unique_end_date_id"
                                        onDatesChange={({ startDate, endDate }) => {
                                            setFromDate(startDate)
                                            setToDate(endDate)
                                        }}
                                        focusedInput={focusedInput}
                                        onFocusChange={focusedInput => setfocusedInput(focusedInput)}
                                        displayFormat='DD-MM-YYYY'
                                        customArrowIcon="|"
                                        orientation={(screen_size > 767) ? "horizontal" : "vertical"}
                                        block={true}
                                    />
                                </div>

                                <div className="col-md-3 col-sm-12 col-height select-box-content">
                                    <label>Location</label>
                                    <select className="form-control select-form" onChange={(e) => { hotelName(e.target.value) }}>
                                        {multi_hotel_details && multi_hotel_details.map(function (item, i) {
                                            return (
                                                <option key={i} value={item.hotel_id}>{item.hotel_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-3 col-sm-12 col-height">
                                    {/* <Link className="standard-btn" to={{
                                        pathname: config.PATH + 'booknow',
                                        search: '?' + (iframe),
                                    }}>Check Availability</Link> */}

                                    <a className="standard-btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Check Availability</a>

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Home;
