import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext, } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'


function Restaurant() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-static-pages-data/' + company_id + '/' + template_id
                        // config.apiEndpoint1 + '/userwb/get-static-pages-data/1685/2'
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                    setwebsiteDetails({ "template_identity": result.template_identity, "about_details": result.about_details,"hotel_menus": result.hotel_menu_details, "hotel_details": result.hotel_details, "seo_details": result.seo_details});
                    window.$('.carousel').carousel({
                        interval:5000
                    });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    // const [websiteDetails] = useContext(WebsiteDetailsContext);
    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });
    return (
        <div>
            {(websiteDetails === undefined) ? <div className="loader" style={{ backgroundImage: "url(" + require('../img/preload.gif') + ")" }}></div> : <Header />}
            {websiteDetails && websiteDetails.seo_details && websiteDetails.seo_details.original.map(function (item, i) {
                if (item.page_name === 'about') {
                    return (
                        <MetaTags key={i}>
                            <meta charSet="UTF-8" />
                            <title>{item.seo_title}</title>
                            <meta name="description" content={item.seo_description} />
                        </MetaTags>
                    )
                }
            })}


           {websiteDetails && <section>
                <div className="standard-top-banner restraunt-top-bg" style={{ backgroundImage: "url(" + require("../img/restraunt.jpg") + ")" }}>
                    <div className="page-title-wrapper">
                        <h1 className="heading__secondary">Restaurant</h1>
                    </div>
                </div>
            </section>}


            { websiteDetails && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <div className="restraunt-slider-wrap">
                            <div id="restraunt-carousel" className="carousel restraunt-carousel-slider slide" data-ride="slide" data-interval="false">

                                <div className="carousel-inner">
                                    <div className="item active">
                                        <div className="row">
                                            <div className="col-md-6 col-right">
                                                <img src={require("../img/restaurant1.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>

                                            <div className="col-md-6 col-left">
                                                <img src={require("../img/restaurant2.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6 col-right">
                                                <img src={require("../img/restaurant3.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>

                                            <div className="col-md-6 col-left">
                                                <img src={require("../img/restaurant4.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6 col-right">
                                                <img src={require("../img/restaurant5.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>

                                            <div className="col-md-6 col-left">
                                                <img src={require("../img/restaurant6.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6 col-right">
                                                <img src={require("../img/restaurant7.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>

                                            <div className="col-md-6 col-left">
                                                <img src={require("../img/restaurant8.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-6 col-right">
                                                <img src={require("../img/restaurant9.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>

                                            <div className="col-md-6 col-left">
                                                <img src={require("../img/restaurant10.jpg")} alt="Restaurant Image" title="Image Showing the Hotel Restaurant" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <a className="left carousel-control" href="#restraunt-carousel" data-slide="prev">
                                    <span className="icon-prev"></span>
                                </a>
                                <a className="right carousel-control" href="#restraunt-carousel" data-slide="next">
                                    <span className="icon-next"></span>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
}
            {websiteDetails && <RestaurantSignature />}
            {websiteDetails && <RestuarantQuotesImage />}
            <a id="" className="scrollup show" title="Go To Top" style={{bottom: "15px"}} onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})}></a>

            {(websiteDetails === undefined) ? '' : <Footer />}

        </div>

    );
}


function RestaurantSignature() {
    return (
        <section>
            <div className="containeer container-space">
                <div className="row">
                    <h1 className="container-heading">Signature Restaurant​</h1>
                </div>
            </div>

            <div className="container container-space">
                <div className="row">
                    <div className="col-md-3 col-padding">
                        <div className="restraunt-slider-wrap">
                            <div id="single-image-carousel" className="carousel single-image-slider slide" data-ride="slide" data-interval="false">
                                <div className="carousel-inner">
                                    <div className="item active">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img src={require("../img/restaurant-food1.jpeg")} alt="Restaurant Food Item" title="Image showing diffrent restaurant food items" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img src={require("../img/restaurant-food2.jpeg")} alt="Restaurant Food Item" title="Image showing diffrent restaurant food items" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img src={require("../img/restaurant-food3.jpeg")} alt="Restaurant Food Item" title="Image showing diffrent restaurant food items" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img src={require("../img/restaurant-food4.jpeg")} alt="Restaurant Food Item" title="Image showing diffrent restaurant food items" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <a className="left carousel-control" href="#single-image-carousel" data-slide="prev">
                                    <span className="icon-prev"></span>
                                </a>
                                <a className="right carousel-control" href="#single-image-carousel" data-slide="next">
                                    <span className="icon-next"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 single-res-padding">
                        <h2 className="restraunt-single-heading">THE PIKU’S INN RESTAURANT</h2>
                        <div>OUR STORY</div>
                        <div className="single-res-desc">The seafood delicacies on offer include illish Bhapa to Pomphret Fry in short we have got the oceans best on our platter.</div>
                        <div className="single-res-desc">It’s really an open secret for all those who visit Digha, a place to relax and savor one’s meal. Our dishes are set using the best ingredients and exclusive combination of spices that are grounded in house which brings out the authenticity to the rich heritage of the cuisine while upholding grander levels of quality</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function RestuarantQuotesImage() {
    return (
        <section>
            <div className="container container-space margin-top">
                <div className="row">
                    <div className="col-md-12 col-left col-right">
                        <div className="restaurant-quote-img">
                            <img src={require("../img/quote-img.jpg")} alt="Restaurant Quotation Image" title="Simple image showing a quote related to restr-aurant and its food" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Restaurant;