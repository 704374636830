import React, { useState,useContext,useEffect } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
  } from "react-google-maps";
  import { compose, withProps } from "recompose";

  
const MyMapComponent = compose(
    withProps({
      googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvbN2yXDGSnDIMmUp9JByD39hIsJhDKok&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div className="maps" style={{ height: `320px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: props.lat, lng: props.lng }}>
      {props.isMarkerShown && (
        <Marker position={{ lat: props.lat, lng:  props.lng  }} />
      )}
    </GoogleMap>
  ));


function MapComponent(){
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-map-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    setwebsiteDetails({"hotel_details":result.hotel_details});

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    return(
      <div>
        {(websiteDetails !==undefined)?
            < MyMapComponent isMarkerShown 
        lat={parseFloat(websiteDetails.hotel_details.original[0].latitude)}
        lng={parseFloat(websiteDetails.hotel_details.original[0].longitude)}/>
        : ''
        }
     
    </div>
    );
}
export default MapComponent;