import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'
import moment from 'moment';

const AboutSection = (props) => {
    const [companyDetails] = useContext(CompanyContext);
    const [aboutSectionData, setAboutSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const aboutSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-about-section-data/' + company_id + '/' + template_id
                    );
                    const result = await aboutSection.json();

                    setAboutSectionData({ "hotel_about_details": result && result.hotel_about_details, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = aboutSectionData && aboutSectionData.section_details
    
    let display_section_title_des = props && props.data

    let iframe_1
    let toDate = new Date();
    let fromDate = new Date();
    toDate.setDate(fromDate.getDate() + 1);
    let to_date = new Date(moment(toDate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromDate).format('MM/DD/YYYY')).getTime();

    return (
        
        <div>

            {section_details &&  !display_section_title_des && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


        {aboutSectionData && aboutSectionData.hotel_about_details && aboutSectionData.hotel_about_details.length > 0 && <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")"}}>
            <div className="container container-space margin-top">
                <div className="row">
                    <div className="accommodation-slider-wrap">
                        <div id="about-hotel-carousel" className="carousel slide" data-ride="slide" data-interval="false">

                            <div className="carousel-inner accommodation-inner">

                                {aboutSectionData.hotel_about_details.map(function (item, id) {
                                    let allData_1 = to_date + "|" + from_date + '|' + item.hotel_id + '|' + "" + '|' + "" + '|' + ""
                                    let encryptData_1 = btoa(allData_1);
                                    // iframe_1 = encryptData_1
                                    return (

                                        <div className={id===0?"item active":"item"} key={id}>
                                            <div className="row">

                                                <div className="col-md-12 padding-zero" key={id}>
                                                    <img src={config.wbImagesEndpoint + '/' + item.image_name} alt={item.about_image_alt} title={"Image of a" + " " + item.title} />
                                                    <figcaption className="fig-cap-padding">
                                                        <h3 className="accom-h3">{item.title}</h3>
                                                        <p className="accom-para" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                        {/* <Link className="accom-btn"
                                                            to={{
                                                                pathname: config.PATH + 'booknow',
                                                                search: '?' + (iframe_1),
                                                            }}
                                                        >Book Online</Link> */}
                                                        <a className="accom-btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData_1}>Book Online</a>

                                                    </figcaption>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                            <a className="left carousel-control-width  carousel-control" href="#about-hotel-carousel" data-slide="prev">
                                <span className="icon-prev"></span>
                            </a>
                            <a className="right carousel-control-width  carousel-control" href="#about-hotel-carousel" data-slide="next">
                                <span className="icon-next"></span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </section>}

        </div>
    
    )
}

export default AboutSection;