import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'


const TestimonialSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [testimonialSectionData, setTestimonialSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const testimonialSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-testimonial-section-data/' + company_id + '/' + template_id
                    );
                    const result = await testimonialSection.json();

                    setTestimonialSectionData({ "testimonial_data": result && result.hotel_testimonials, "section_details": result && result.section_details, "all_hotels": result && result.all_hotels });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = testimonialSectionData && testimonialSectionData.section_details

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [validname, setvalidname] = useState(true);
    const [validemail, setvalidemail] = useState(true);
    const [validnumber, setvalidnumber] = useState(true);
    const [message, setmessage] = useState('');
    const [subject, setSubject] = useState('');
    const [successmsg, setsuccessmsg] = useState(true)

    const contactform = () => {

        if (validname === true && validemail === true && validnumber === true && name.length !== 0 && email.length !== 0 && number.length !== 0) {
            fetch(config.apiEndpoint + '/userwb/send-user-query',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": testimonialSectionData && testimonialSectionData.all_hotels[0].hotel_id,
                        "name": name,
                        "email": email,
                        "mobile": number,
                        "message": message

                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(false)
                })

            fetch(config.apiEndpoint + '/insert-customer-data',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": testimonialSectionData && testimonialSectionData.all_hotels[0].hotel_id,
                        "user_name": name,
                        "user_email_id": email,
                        "user_contact_number": number,
                        "user_message": message
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    // setsuccessmsg(true)
                })

        }
        else {
            if (name.length === 0 && email.length === 0 && number.length === 0) {
                setvalidname(false);
                setvalidemail(false);
                setvalidnumber(false);
            }

        }
    }



    const validateName = (name) => {
        setname(name)
        if (name.match(/[A-Za-z]{2,}/)) {
            setvalidname(true)
        }
        else {
            setvalidname(false)

        }

    }

    const validateSubject = (subject) => {
        setSubject(subject)
    }

    const validateEmail = (email) => {
        setemail(email)

        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            setvalidemail(true)
        }
        else {
            setvalidemail(false)
        }

    }
    const validateNumber = (number) => {
        setnumber(number)
        if (number.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
            setvalidnumber(true)
        }
        else {
            setvalidnumber(false)

        }
    }
    const validateMessage = (message) => {
        setmessage(message)
    }

    return (

        <div>

            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}

            <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="testimonial-slider-wrap">
                                <div id="testimonial-carousel" className="carousel slide" data-ride="slide" data-interval="false">
                                    <ol className="carousel-indicators">

                                        {testimonialSectionData && testimonialSectionData.testimonial_data && testimonialSectionData.testimonial_data.map(function (item, i) {
                                            return (
                                                <li data-target="#testimonial-carousel" data-slide-to={i} className={i === 0 ? "active" : ''} key={i}></li>
                                            )
                                        })}
                                    </ol>

                                    <div className="carousel-inner">

                                        {testimonialSectionData && testimonialSectionData.testimonial_data && testimonialSectionData.testimonial_data.map(function (item, i) {
                                            return (
                                                <div className={i === 0 ? "item active" : "item"} key={i}>
                                                    <div className="testimonail-inner-wrap">
                                                        <div className="guest-name">{item.testimonial_title}</div>
                                                        <div className="guest-message" dangerouslySetInnerHTML={{ __html: item.testimonial_description }}></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-4 testimonail-input">
                            <div className="row">
                                <div className="col-md-6">
                                    <div hidden={validname} style={{ color: 'red' }}>Invalid Name</div>
                                    <input className="input-style" placeholder="Full Name *" type="text" value={name} onChange={(e) => validateName(e.target.value)} />
                                </div>

                                <div className="col-md-6">
                                    <div hidden={validemail} style={{ color: 'red' }}>Invalid Email</div>
                                    <input className="input-style" placeholder="Your Email *" type="email" value={email} onChange={(e) => validateEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <input className="input-style" placeholder="Subject *" type="text" value={subject} onChange={(e) => validateSubject(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div hidden={validnumber} style={{ color: 'red' }}>Invalid Number</div>
                                    <input className="input-style" placeholder="Number *" type="text" value={number} onChange={(e) => validateNumber(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <textarea className="text-area-style" placeholder="Your Message *" row="10" value={message} onChange={(e) => validateMessage(e.target.value)}></textarea>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="testimonail-btn" onClick={() => contactform()}>
                                        <a>Send Message</a>
                                    </div>
                                    <div hidden={successmsg} style={{ color: 'red', fontSize: 15, fontWeight: 'bold' }}>Submitted Successfully !</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </div>

    );
}

export default TestimonialSection;