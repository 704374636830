import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import history from '../../history';


function Footer() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-footer-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;

                    var all_menu_details = {
                        "footer": {
                            "footer_menus": result.footer_menu_details,
                        }
                    }


                    let numbers = [];
                    let emails = [];
                    if (result && result.hotel_details) {
                        numbers = result.hotel_details.original[0].mobile.split(',');
                        emails = result.hotel_details.original[0].email_id.split(',');
                    }


                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details, "hotel_services": result.service_details && result.service_details.original, "phone_numbers": numbers, "emails": emails });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])


    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    var hotelDetails = websiteDetails && websiteDetails.hotel_details.original[0]

    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }

    }
    let encoded_url = encodeURIComponent(websiteDetails && websiteDetails.hotel_details && websiteDetails.hotel_details.original[0].facebook_link)

    return (
        <div>
            <footer>
                <div className="container container-space margin-top">
                    <div className="row">
                        <div className="col-md-9 footer-col">
                            <div className="row">
                                <div className="col-md-6 text-left col-sm-4">
                                    {/* Footer Logo  */}
                                    <div className="footer-logo">
                                        <a onClick={() => history.push(config.PATH + 'home')}>
                                            {websiteDetails && websiteDetails.template_identity &&
                                                <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt} />
                                            }
                                        </a>
                                    </div>

                                    {/* Hotel Address  */}
                                    <div className="footer-text">
                                        {websiteDetails && websiteDetails.hotel_details && websiteDetails.hotel_details.original[0].hotel_address}
                                    </div>

                                    {/* Hotel Contact Details  */}
                                    {websiteDetails && websiteDetails.hotel_details &&
                                        <div className="footer-contact-wrap">

                                        {websiteDetails && websiteDetails.emails.length > 0 && websiteDetails.emails.map(function (items, i) {
                                            return (
                                                <div className="footer-email" key={i}>Email:
                                                    <span>  <a href={"mailto:" + items} className="footer-number-email">{items}</a></span>
                                                </div>
                                            )
                                        })}
                                            <div className="footer-mobile">Mobile:
                                        {websiteDetails && websiteDetails.phone_numbers.length > 0 && websiteDetails.phone_numbers.map(function (items, i) {
                                                return (
                                                    <span key={i}>  <a href={"tel:" + items} className="footer-number-email" >{items}</a>{i < websiteDetails.phone_numbers.length - 1 && <span>,</span>}</span>
                                                )
                                            })}
                                            </div>


                                        </div>
                                    }
                                </div>

                                {/* Footer Menu  */}

                                <div className="col-md-3 col-sm-4">
                                    <ul className="footer-menu">
                                        {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.footer.footer_menus.map(function (item, i) {

                                            if(i<4){
                                            return (
                                                <li key={i}><a onClick={() => redirectTo(item)}>{item.menu_name}</a></li>
                                            )}
                                        })}
                                    </ul>
                                </div>


                                <div className="col-md-3 col-sm-4">
                                    <ul className="footer-menu">
                                        {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.footer.footer_menus.map(function (item, i) {
                                            if (i >= 4) {
                                                return (
                                                    <li key={i}><a onClick={() => redirectTo(item)}>{item.menu_name}</a></li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>


                            </div>
                        </div>

                        <div className="col-md-3 footer-col">
                            {/* Facebook Widget Section  */}
                            {websiteDetails && websiteDetails.hotel_details &&
                                <div className="facebook-widget">
                                    <iframe src={"https://www.facebook.com/plugins/page.php?href=" + encoded_url + "&tabs=timeline&width=300&height=250&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"}
                                        width="300" height="250" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                                </div>}

                            {/* Footer Socila Links  */}
                            <div className="footer-social-nav">
                                <ul className="footer-social-links">
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.facebook_link) ? hotelDetails.facebook_link : "#"}><i className="fa fa-facebook"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.twitter_link) ? hotelDetails.twitter_link : "#"}><i className="fa fa-twitter"></i></a></li>
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.linked_in_link) ? hotelDetails.linked_in_link : "#"}><i className="fa fa-linkedin"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-pinterest"></i></a></li> */}
                                    <li><a href={(websiteDetails && hotelDetails && hotelDetails.instagram_link) ? hotelDetails.instagram_link : "#"}><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="footer_copyright_area">
                    <div className="container">
                        <div className="pull-left">
                        {websiteDetails && hotelDetails &&
                            <h4>Copyright © {hotelDetails.hotel_name} . All rights reserved. </h4>
                        }
                        </div>
                        <div className="pull-right">
                            <h4>Created by: <a href="https://bookingjini.com/"target="_blank"><img src={config.apiEndpoint + '/public/templateimage/jini_logo.png'} style={{marginTop:"-10px",width:"120px"}}/></a></h4>
                        </div>
                    </div>
                </div>

            </footer>







        </div>
    )
}
export default Footer