import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext, } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import MapComponent from './MapComponent'
import history from '../../history'
import "react-image-lightbox/style.css";

function AboutDigha() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-static-pages-data/' + company_id + '/' + template_id
                        // config.apiEndpoint1 + '/userwb/get-static-pages-data/1685/2'
                    );
                    const json = await staticPageData.json();
                    const result = json.data;


                    setwebsiteDetails({ "template_identity": result.template_identity, "about_details": result.about_details,"hotel_menus": result.hotel_menu_details, "hotel_details": result.hotel_details, "seo_details": result.seo_details});
                    window.$('.carousel').carousel({
                        interval:5000
                    });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    // const [websiteDetails] = useContext(WebsiteDetailsContext);
    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });
    return (
        <div>
            {(websiteDetails === undefined) ? <div className="loader" style={{ backgroundImage: "url(" + require('../img/preload.gif') + ")" }}></div> : <Header />}
            {websiteDetails && websiteDetails.seo_details && websiteDetails.seo_details.original.map(function (item, i) {
                if (item.page_name === 'about') {
                    return (
                        <MetaTags key={i}>
                            <meta charSet="UTF-8" />
                            <title>{item.seo_title}</title>
                            <meta name="description" content={item.seo_description} />
                        </MetaTags>
                    )
                }
            })}


            {websiteDetails && <section>
                <div className="standard-top-banner digha-bg" style={{ backgroundImage: "url(" + require("../img/digha.jpg") + ")" }}>
                    <div className="page-title-wrapper">
                        <h1 className="heading__secondary">About Digha</h1>
                    </div>
                </div>
            </section>}


    {websiteDetails && <section>
        <div className="container about-digha-margin margin-top">
            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/digha-beach.jpg")} alt="Digha Beach Images" title="Image showing a scene of Digha Beach"/>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-desc">Digha is West Bengal’s most popular sea resort. It is located 187 Km south west of Kolkata. Digha has a low gradient with a shallow sand beach extending upto 7 Km in length and has gentle rolling waves. The scenic beauty of this place is charming and alluring. The beach is girdled with Casuarinas plantations along the coast enhancing its beauty. These trees apart from beautifying also aid in reducing erosion of the dunes. One can view both sunrise and sunset at the Digha sea beach. The sunset and sunrise reflecting the salty waters of the Bay of Bengal is something straight off an artist’s canvas.</div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>Tajpur</strong><br/>Tajpur is one of the most hidden beaches in India. Tajpur beach lies in the Purba Medinipur district of South Bengal, one of the southern districts of West Bengal. It lies on the Kolkata – Digha route and just 16 Km before Digha. Tajpur is one of Bengal’s recently discovered tourist destination that lies close to ever-popular Mandarmoni and Shankarpur.In Tajpur, prime attraction is its sea beach with a dense of tamarisk trees. Red crabs are found during day time as it is looks like red carpet which welcoming you. Seagulls are also being seen at the break of dawn here at Tajpur.Now a day Tajpur became the most popular destination among Bengal people as well as to other countries people i.e. Foreigners.</div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/tajpur-sea-beach.jpg")} alt="Tajpur Sea Beach" title="Image showing a Tajpur Sea Beach"/>
                    </div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/new-digha.jpg")} alt="New Digha Beach Images" title="Image showing a scene of New Digha Beach"/>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>New Digha</strong><br/>New Digha is the place which is the new attractive town with the sites of beaches and attractive point for holidays. Besides the availability of best hotels and the beaches another attractive point for the New Digha is the Science Centre. Science centre increases the beauty of New Digha which got established by the National Council of Science Museums. It is famous centre just because it makes the learning of science through the ways which automatically catches the eye of the tourists. There is a fun glass in the centre which can measure the height of tourists individually. A Skeleton has been companied with racing of bicycle in such a way which makes the tourists to enjoy. Other tourist point in New Digha is the small park accompanied with a small lake which provides the facility of boat riding.</div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>Mandarmani</strong><br/>Mandarmani is a large and rapidly developing seaside resort village. It lies in East Midnapore district and at the northern end of the Bay of Bengal. Mandarmani is connected to Kolkata/Howrah by a highway. From Contai this can be travelled through a place called Chaulkhola. Mandarmani boasts of a 13 km long motorable beach, probably the longest motorable beach road in India. This is a drive-in beach.</div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/mandarmani.jpg")} alt="Mandarmani Sea Beach" title="Image showing a Mandarmani Sea Beach"/>
                    </div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/junput.jpg")} alt="Junput Beach Images" title="Image showing a scene of Junput Beach Images"/>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>Junput</strong><br/>Junput is 177 km from Kolkata and 40 km from Digha; it also has a beautiful sea beach. The place offers spectacular sea-view and lines of casuarina trees. The beach is unspoilt. Brackish water fish cultivation and research are done here by the State Government Fisheries Department. For accommodation, there is an Inspection Bungalow under the Superintendent of Fisheries and a few private hotels.</div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>Udaipur</strong><br/>This is a spectacular beach, almost virgin, 2 km west of Digha, in West Bengal Orissa Border. One part of this clandestine beach is in West Bengal and the other in Orissa. One enters this beach just walking or driving along Bengal-Orissa border. This beach has not been explored so far. The beach has some USPs which has to be experienced to be believed.</div>
                    <div className="digha-about-desc">The vegetation, typical of slopes in Eastern Ghats and the great Casuarina atmosphere, makes this beach, a one of a kind experience. The nearby delta, where the mighty Subarnarekha merges into the Bay of Bengal, is an additional attraction.</div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/udaipur.jpg")} alt="Udaipur Sea Beach" title="Image showing a Udaipur Sea Beach"/>
                    </div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/shankarpur.jpg")} alt="Shankarpur Beach Images" title="Image showing a scene of Shankarpur Beach Images"/>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>Shankarpur</strong><br/>Shankarpur is a twin beach of around 14 km east of Digha in West Bengal. It is also a regular fishing harbor. The mornings are cool, when fishermen can be seen hauling their huge nets out of the sea. The morning sun reflecting on the sea waves in the east, and the local fish boats on the coast offer excellent photographic opportunities.</div>
                </div>
            </div>

            <div className="row row-margin">
                <div className="col-md-6">
                    <div className="digha-about-desc"><strong>Attractions & Activities::Digha Sea Beach</strong><br/>The attractive and eye catching point of Digha is the beach which is flat and hard which is spread over a large area. Digha is the beach which is known as one of the widest beaches all over the world. West Bengal is famous place for the tourist only with Digha beach which increases the beauty of West Bengal.</div>
                    <div className="digha-about-desc">The Digha Beach is another stunning beach about 14 km from Shankarpur. The beach is equally beautiful and clean and arguably offers the most beautiful sunset view in all of West Bengal.</div>
                </div>

                <div className="col-md-6">
                    <div className="digha-about-img">
                        <img src={require("../img/beach-activities.jpg")} alt="Digha Beach Activities Beach" title="Image showing a Digha Beach Activities Beach"/>
                    </div>
                </div>
            </div>

        </div>
    </section>
}
<a id="" className="scrollup show" title="Go To Top" style={{bottom: "15px"}} onClick={()=>window.scrollTo({top: 0, behavior: 'smooth'})}></a>

            {(websiteDetails === undefined) ? '' : <Footer />}

        </div>

    );
}

export default AboutDigha;