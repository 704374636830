import React from 'react'
import Header from './Header'
import Footer from './Footer'
import NearbyPlacesSection from './sections/NearbyPlacesSection';


function ReachUs() {

    let image="https://d3ki85qs1zca4t.cloudfront.net/images/1606136017DSC00073.JPG";

    return (

        <div>
            <Header />
            <section>
                <div className="standard-top-banner digha-bg" style={{ backgroundImage: "url(" + image + ")" }}>
                    <div className="page-title-wrapper">
                        <h1 className="heading__secondary">Reach Us</h1>
                    </div>
                </div>
            </section>


            <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">Local Distances From Blue Lily Beach Resort</h1>
                    </div>
                </div>
            </section>


            <NearbyPlacesSection />
          
            <Footer />
        </div>

    )

}

export default ReachUs;
