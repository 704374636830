import React from 'react'
import Header from './Header'
import Footer from './Footer'


function Careers() {

    let image= "https://d3ki85qs1zca4t.cloudfront.net/images/1606213290DSC_0022.JPG"

    return (

        <div>
            <Header />
            <section>
                <div className="standard-top-banner digha-bg" style={{ backgroundImage: "url(" + image + ")" }}>
                    <div className="page-title-wrapper">
                        <h1 className="heading__secondary">Careers</h1>
                    </div>
                </div>
            </section>


            <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">Careers</h1>
                    </div>
                </div>
            </section>



            <section>
                <div className="container about-digha-margin margin-top">

                    <div className="row row-margin">
                        <div className="col-md-6">
                            <div className="digha-about-desc">Our primary commitment is to exceed all expectations of our employees, guests and associates. We are determined to serve them in the following way: <br /><br /><strong>Towards Our Employees:</strong>We inculcate the ideas of mutual respect, smiling attitude, positive work environment in providing a passionate service towards our guests. We create opportunities for their advancement, reward hard work and take pride in our success.<br /><br /><strong>Towards Our Associates:</strong>We propagate ourselves as “Turn around Specialist” and are committed to generate high revenue, guaranteed ROI through a superior and transparent hospitality management.<br /><br />You are welcome to send us your resume by email on <a className ="careers-page" href="mailto:hr@bluelilybeachresort.com">hr@bluelilybeachresort.com</a>. Please do include present & expected compensation and position/role sought.</div>
                        </div>

                        <div className="col-md-6">
                            <div className="digha-about-img">
                                <img src={"https://d3ki85qs1zca4t.cloudfront.net/images/1606213335careers.jpg"} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </div>

    )

}

export default Careers;
