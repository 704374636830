import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'
import moment from 'moment';


const PackageSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [packageSectionData, setPackageSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let packages_data = []
                    const packageSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-package-section-data/' + company_id + '/' + template_id
                    );
                    const result = await packageSection.json();


                    if (result && result.default_hotel) {
                        for (let i = 0; i < result.hotel_packages.length; i++) {
                            if (result.hotel_packages[i].hotel_id === result.default_hotel) {
                                packages_data.push(result.hotel_packages[i]);
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < result.hotel_packages.length; i++) {
                            if (result.hotel_packages[i].hotel_id === result.all_hotels_data[0].hotel_id) {
                                packages_data.push(result.hotel_packages[i]);
                            }
                        }
                    }


                    setPackageSectionData({ "packages_data": packages_data, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = packageSectionData && packageSectionData.section_details

    let toDate = new Date();
    let fromDate = new Date();
    toDate.setDate(fromDate.getDate() + 1);
    let to_date = new Date(moment(toDate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromDate).format('MM/DD/YYYY')).getTime();

    return (
        <div>

            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


            {packageSectionData && packageSectionData.packages_data && packageSectionData.packages_data.length > 0 && <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top">
                    <div className="row">
                        <div className="package-slider-wrap">
                            <div id="package-carousel" className="carousel package-carousel-slider slide" data-ride="slide" data-interval="false">
                                <div className="carousel-inner">

                                    {packageSectionData.packages_data.map(function (item, i) {
                                        var send_title = item.package_title.replace(/[^a-zA-Z0-9 ]/g, "");
                                        send_title = send_title.replace(/ +/g, "_");
                                        send_title = send_title.toLowerCase();

                                        let allData = from_date + '|' + to_date + '|' + item.hotel_id + '|' + "" + '|' + "" + '|' + ""
                                        let encryptData = btoa(allData);
                                        // let iframe = encryptData

                                        return (
                                            <div className={i === 0 ? "item active" : "item"} key={i}>
                                                <img src={config.wbImagesEndpoint + '/' + item.package_image} alt={item.package_image_alt + " " + "Package Image"} title={"Image showing a" + " " + item.package_title} />
                                                <figcaption>
                                                    <div className="fig-caption-text">
                                                        <span className="fig-inner-text">{item.package_title}</span>
                                                        <span className="package-btn-link">
                                                            <Link className="more-details" tabIndex="-1" to={{
                                                                pathname: config.PATH + '' + send_title
                                                            }} >
                                                                More Details
                                                        </Link>&nbsp;
                                                        {/* <Link className="enquire-now"
                                                                to={{
                                                                    pathname: config.PATH + 'booknow',
                                                                    search: '?' + (iframe),
                                                                }}
                                                            >Enquire Now</Link> */}
                                                            <a className="enquire-now" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Enquire Now</a>
                                                        </span>
                                                    </div>
                                                </figcaption>
                                            </div>
                                        )
                                    })}
                                </div>

                                <a className="left carousel-control-width carousel-control" href="#package-carousel" data-slide="prev">
                                    <span className="icon-prev"></span>
                                </a>
                                <a className="right carousel-control-width carousel-control" href="#package-carousel" data-slide="next">
                                    <span className="icon-next"></span>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>}

        </div>

    );
}



export default PackageSection;