import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import Header from './Header'
import Footer from './Footer'
import MetaTags from 'react-meta-tags';
import CustomFooterSection from './sections/CustomFooterSection';

function Outlets() {
    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [outletPageData, setOutletPageData] = useState();

    var split_url;
    let hotel_index
    let service_index
    const parseURLParams = (url) => {
        var queryStart = url.indexOf("?") + 1,
            queryEnd = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1);
        split_url = query
    }
    let url = window.location.href;
    let get_encoded_url = parseURLParams(url);

    let name_hotel = split_url.slice(0, split_url.indexOf("/"))
    let name_service = split_url.slice(split_url.indexOf("/") + 1, split_url.length)


    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let page_details = [];
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-outlet-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();


                    if (result && result.all_hotels) {
                        let hotel_name;
                        for (let i = 0; i < result.all_hotels.length; i++) {
                            hotel_name = result.all_hotels[i].hotel_name.replace(/[^a-zA-Z0-9 ]/g, "");
                            hotel_name = hotel_name.replace(/ +/g, "_");
                            hotel_name = hotel_name.toLowerCase();


                            if (hotel_name === name_hotel) {
                                hotel_index = result.all_hotels[i].hotel_id
                            }
                        }
                    }
                    if (result && result.outlet_page_data) {
                        let service_name;
                        for (let i = 0; i < result.outlet_page_data.length; i++) {
                            service_name = result.outlet_page_data[i].title.replace(/[^a-zA-Z0-9 ]/g, "");
                            service_name = service_name.replace(/ +/g, "_");
                            service_name = service_name.toLowerCase();
                            if (service_name === name_service) {
                                service_index = result.outlet_page_data[i].services_id
                            }

                            if (result.outlet_page_data[i].hotel_id === hotel_index && result.outlet_page_data[i].services_id === service_index) {
                                page_details = result.outlet_page_data[i];
                            }
                        }
                    }
                    var list = [];


                    if (result && result.outlets_list.length > 0) {
                        for (let i = 0; i < result.outlets_list.length; i++) {
                            if (result.outlets_list[i].hotel_id === hotel_index && result.outlets_list[i].services_id === service_index) {
                                list.push(result.outlets_list[i]);
                            }
                        }
                    }

                    if (result && result.page_section && result.page_section.page_section) {
                        for (let i = 0; i < result.page_section.page_section.length; i++) {
                            if (result.page_section.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }

                    setOutletPageData({ "outlet_page_data": page_details, "outlets_list": list,"display_default_footer": display_default_footer })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val,name_service])

    let websitefont = '';

    let video_banner_link = outletPageData && outletPageData.outlet_page_data && outletPageData.outlet_page_data.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = outletPageData && outletPageData.outlet_page_data && outletPageData.outlet_page_data.video_banner


    return (
        <div>
            {(outletPageData === undefined) ? <div className="loader" style={{ backgroundImage: "url(" + require('../img/preload.gif') + ")" }}></div> : <Header />}

            {outletPageData && outletPageData.outlet_page_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{outletPageData.outlet_page_data.seo_title}</title>
                    <meta name="description" content={outletPageData.outlet_page_data.seo_description} />
                </MetaTags>
            }

            {outletPageData && outletPageData.outlet_page_data && outletPageData.outlet_page_data.banner && <section>
                <div className="standard-top-banner about-top-bg" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + outletPageData.outlet_page_data.banner + ")" }}>
                    <div className="page-title-wrapper">
                        <h1 className="heading__secondary">{outletPageData.outlet_page_data.banner_title}</h1>
                    </div>
                </div>
            </section>}


            {outletPageData && outletPageData.outlet_page_data && outletPageData.outlet_page_data.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + outletPageData.outlet_page_data.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + outletPageData.outlet_page_data.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {outletPageData && outletPageData.outlet_page_data && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{outletPageData.outlet_page_data.page_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: outletPageData.outlet_page_data.page_description }}></p>
                    </div>
                </div>
            </section>}


            <div className="container">
                <section>
                    <div className="container about-digha-margin margin-top">
                        {outletPageData && outletPageData.outlets_list && outletPageData.outlets_list.map(function (item, id) {
                            if (id % 2 === 0) {
                                return (
                                    <div className="row row-margin" key={id}>
                                        <div className="col-md-6">
                                            <div className="digha-about-img">
                                                <img src={config.wbImagesEndpoint + '/' + item.image} alt={item.image_alt} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="digha-about-desc"><strong>{item.title}</strong><br />
                                                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="row row-margin" key={id}>
                                        <div className="col-md-6">
                                            <div className="digha-about-desc"><strong>{item.title}</strong><br />
                                                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="digha-about-img">
                                                <img src={config.wbImagesEndpoint + '/' + item.image} alt={item.image_alt} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}

                    </div>
                </section>


            </div>

            <a id="" className="scrollup show" title="Go To Top" style={{ bottom: "15px" }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}></a>

            {outletPageData && outletPageData.display_default_footer === false && <CustomFooterSection />}
            {outletPageData && outletPageData.display_default_footer === true && <Footer />}
        </div>
    )
}

export default Outlets;
