import React, { useState, useContext, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { CompanyContext } from "../../global_setting/Store";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import config from "../../../src/config.json";
import OfferSection from "./sections/OfferSection";
import AboutSection from "./sections/AboutSection";
import ServiceSection from "./sections/ServiceSection";
import PackageSection from "./sections/PackageSection";
import RoomSection from "./sections/RoomSection";
import GallerySection from "./sections/GallerySection";
import TestimonialSection from "./sections/TestimonialSection";
import FacebookSection from "./sections/FacebookSection";
import EventSection from "./sections/EventSection";
import NewsletterSection from "./sections/NewsletterSection";
import ContactSection from "./sections/ContactSection";
import NearbyPlacesSection from "./sections/NearbyPlacesSection";
import FacilitiesSection from "./sections/FacilitiesSection";
import PromotionalBannerSection from "./sections/PromotionalBannerSection";
import PromotionSection from "./sections/PromotionSection";
import AwardsSection from "./sections/AwardsSection";
import BookingBenefitsSection from "./sections/BookingBenefitsSection";
import CustomFooterSection from "./sections/CustomFooterSection";
import CustomSection from "./sections/CustomSection";

function Blog() {
	const [companyDetails] = useContext(CompanyContext);
	let company_id_val = companyDetails && companyDetails.company_id;
	const [blogPageData, setBlogPageData] = useState();

	useEffect(() => {
		(async function () {
			try {
				if (company_id_val) {
					let company_id = companyDetails.company_id;
					let template_id = companyDetails.template_id;
					let display_default_footer = true;

					const pageData = await fetch(
						config.apiEndpoint +
							"/wb-frontview/get-frontview-blog-page-data/" +
							company_id +
							"/" +
							template_id
					);
					const result = await pageData.json();

					if (
						result &&
						result.blog_page_data &&
						result.blog_page_data.page_section
					) {
						for (
							let i = 0;
							i < result.blog_page_data.page_section.length;
							i++
						) {
							if (
								result.blog_page_data.page_section[i].section_names ===
								"Custom Footer"
							) {
								display_default_footer = false;
							}
						}
					}

					setBlogPageData({
						blog_page_data: result && result.blog_page_data,
						page_section:
							result &&
							result.blog_page_data &&
							result.blog_page_data.page_section,
						hotel_blogs: result && result.hotel_blogs,
						display_default_footer: display_default_footer,
					});
					window.$(".carousel").carousel({
						interval: 5000,
					});
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [company_id_val]);

	const [scrolled, setscroll] = useState(false);
	if (scrolled === false) {
		window.scrollTo(0, 0);
	}

	window.addEventListener("scroll", function () {
		setscroll(true);
	});

	let video_banner_link =
		blogPageData &&
		blogPageData.blog_page_data &&
		blogPageData.blog_page_data.video_banner_link;
	video_banner_link =
		video_banner_link &&
		video_banner_link.slice(
			video_banner_link.indexOf("."),
			video_banner_link.length
		);
	video_banner_link =
		video_banner_link &&
		video_banner_link.slice(
			video_banner_link.indexOf("/") + 1,
			video_banner_link.length
		);

	let display_video_banner_link =
		blogPageData &&
		blogPageData.blog_page_data &&
		blogPageData.blog_page_data.video_banner;

	return (
		<div>
			{blogPageData === undefined ? (
				<div
					className="loader"
					style={{
						backgroundImage: "url(" + require("../img/preload.gif") + ")",
					}}
				></div>
			) : (
				<Header />
			)}
			{blogPageData && blogPageData.blog_page_data && (
				<MetaTags>
					<meta charSet="UTF-8" />
					<title>{blogPageData.blog_page_data.seo_title}</title>
					<meta
						name="description"
						content={blogPageData.blog_page_data.seo_description}
					/>
				</MetaTags>
			)}

			{blogPageData &&
				blogPageData.blog_page_data &&
				blogPageData.blog_page_data.banner && (
					<section>
						<div
							className="standard-top-banner digha-bg"
							style={{
								backgroundImage:
									"url(" +
									config.wbImagesEndpoint +
									"/" +
									blogPageData.blog_page_data.banner +
									")",
							}}
						>
							<div className="page-title-wrapper">
								<h1 className="heading__secondary">
									{blogPageData.blog_page_data.banner_title}
								</h1>
							</div>
						</div>
					</section>
				)}

			{blogPageData &&
				blogPageData.blog_page_data &&
				blogPageData.blog_page_data.video_banner && (
					<section>
						<div className="video-date-picker-wrapper">
							<div className="video-wrapper">
								<video
									playsInline="playsinline"
									autoPlay="autoplay"
									muted="muted"
									loop="loop"
								>
									<source
										src={
											config.wbImagesEndpoint +
											"/" +
											blogPageData.blog_page_data.video_banner
										}
										type="video/mp4"
									/>
									<source
										src={
											config.wbImagesEndpoint +
											"/" +
											blogPageData.blog_page_data.video_banner
										}
										type="video/ogg"
									/>
								</video>
							</div>
						</div>
					</section>
				)}

			{!display_video_banner_link && video_banner_link && (
				<section>
					<div className="video-date-picker-wrapper">
						<div className="video-container-youtube">
							<iframe
								type="text/html"
								className="video-youtube"
								src={
									"https://www.youtube.com/embed/" +
									video_banner_link +
									"?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"
								}
								allow="autoplay"
								frameBorder="0"
								allowFullScreen
							></iframe>
						</div>
					</div>
				</section>
			)}

			{blogPageData && blogPageData.blog_page_data && (
				<section>
					<div className="container container-space margin-top">
						<div className="row">
							<h1 className="container-heading">
								{blogPageData.blog_page_data.page_title}
							</h1>
							<p
								className="container-paragraph"
								dangerouslySetInnerHTML={{
									__html: blogPageData.blog_page_data.page_description,
								}}
							></p>
						</div>
					</div>
				</section>
			)}

			{blogPageData && <BlogList data={blogPageData.hotel_blogs} />}

			{blogPageData &&
				blogPageData.page_section &&
				blogPageData.page_section.map(function (item, i) {
					if (item.section_names === "Promotional Banner") {
						return <PromotionalBannerSection key={i} />;
					}

					if (item.section_names === "Newsletter") {
						return <NewsletterSection key={i} />;
					}

					if (item.section_names === "About Us") {
						return <AboutSection key={i} />;
					}

					if (item.section_names === "Rooms") {
						return <RoomSection key={i} />;
					}

					if (item.section_names === "Gallery") {
						return <GallerySection key={i} />;
					}

					if (item.section_names === "Contact Us") {
						return <ContactSection key={i} />;
					}

					if (item.section_names === "Services") {
						return <ServiceSection key={i} />;
					}

					if (item.section_names === "Nearby Places") {
						return <NearbyPlacesSection key={i} />;
					}

					if (item.section_names === "Testimonial") {
						return <TestimonialSection key={i} />;
					}

					if (item.section_names === "Facility") {
						return <FacilitiesSection key={i} />;
					}

					if (item.section_names === "Package") {
						return <PackageSection key={i} />;
					}

					if (item.section_names === "Event") {
						return <EventSection key={i} />;
					}

					if (item.section_names === "Offer") {
						return <OfferSection key={i} />;
					}

					if (item.section_names === "Facebook") {
						return <FacebookSection key={i} />;
					}
					if (item.section_names === "Promotions") {
						return <PromotionSection key={i} />;
					}

					if (item.section_names === "Awards") {
						return <AwardsSection key={i} />;
					}

					if (item.section_names === "Booking Benfits") {
						return <BookingBenefitsSection key={i} />;
					}

					if (item.section_type === "custom") {
						return (
							<CustomSection
								key={i}
								section_id={item.id}
								name={item.section_names}
							/>
						);
					}

					if (item.section_names === "Custom Footer") {
						return <CustomFooterSection key={i} />;
					}
				})}

			{blogPageData &&
				blogPageData.display_default_footer &&
				blogPageData.display_default_footer === true && <Footer />}
			<a
				id=""
				className="scrollup show"
				title="Go To Top"
				style={{ bottom: "15px" }}
				onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
			></a>
		</div>
	);
}

function BlogList(props) {
	let list_data = props.data;
	let websitefont = "";

	const [search, setsearch] = useState("");
	const [searchtitle, setsearchtitle] = useState("");
	const [searchdes, setsearchdes] = useState("");
	const [searchdate, setsearchdate] = useState("");
	const [valid, setvalid] = useState(false);
	const [active, setactive] = useState(false);
	const Search = () => {
		if (search === "") {
			setactive(false);
			setvalid(true);
		} else {
			setvalid(false);
			list_data &&
				list_data.map(function (item, i) {
					var searchdate = new Date(item.created_at);
					var search_date = searchdate.toDateString().substr(4, 11);
					var titl = item.blog_title.toLowerCase();
					var desc = item.blog_description.toLowerCase();
					var searchd = search.toLowerCase();
					var dates = search_date.toLowerCase();
					if (
						titl.includes(searchd) ||
						desc.includes(searchd) ||
						dates.includes(searchd)
					) {
						setactive(true);
						setsearchtitle(item.blog_title);
						setsearchdes(item.blog_description);
						setsearchdate(search_date);
					} else {
						setactive(true);
						setsearchtitle("");
						setsearchdes("");
						setsearchdate("");
					}
				});
		}
	};

	return (
		<div>
			{list_data && (
				<section className="blog_details_area">
					<div className="container">
						<div className="row">
							<div className="col-md-3 pull-left">
								<div className="sidebar_area">
									<aside className="r_widget search_widget">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder="Enter Search Keywords"
												value={search}
												onChange={(e) => setsearch(e.target.value)}
											/>
											<span className="input-group-btn">
												<button
													className="btn btn-default"
													type="button"
													onClick={() => Search()}
												>
													<i className="icon icon-Search fa fa-search"></i>
												</button>
											</span>
										</div>
										<div hidden={valid === false} style={{ color: "red" }}>
											Please Enter Keywords !!!
										</div>
									</aside>
									{active ? (
										<aside className="r_widget recent_widget">
											<div className="r_widget_title">
												<h3>Search Results</h3>
											</div>
											<div className="recent_inner">
												{list_data &&
													list_data.map(function (item, i) {
														var rdate = new Date(item.created_at);
														var rcreate_date = rdate
															.toDateString()
															.substr(4, 11);
														let send_title = item.blog_title.replace(
															/[^a-zA-Z0-9 ]/g,
															""
														);
														send_title = send_title.replace(/ +/g, "_");
														send_title = send_title.toLowerCase();
														if (
															item.blog_title === searchtitle ||
															item.blog_description === searchdes ||
															rcreate_date === searchdate
														) {
															return (
																<div className="recent_item" key={i}>
																	<Link
																		className="blog_img"
																		to={{
																			pathname: config.PATH + "" + send_title,
																		}}
																	>
																		<h4>{item.blog_title}</h4>
																	</Link>
																	<h5>{rcreate_date}</h5>
																</div>
															);
														} else {
															return (
																<div className="recent_item" key={i}>
																	<h4>No Results Found !!!</h4>
																</div>
															);
														}
													})}
											</div>
										</aside>
									) : (
										<aside className="r_widget recent_widget">
											<div className="r_widget_title">
												<h3>Recent News</h3>
											</div>
											<div className="recent_inner">
												{list_data &&
													list_data.map(function (item, i) {
														var rdate = new Date(item.created_at);
														var rcreate_date = rdate
															.toDateString()
															.substr(4, 11);
														let send_title = item.blog_title.replace(
															/[^a-zA-Z0-9 ]/g,
															""
														);
														send_title = send_title.replace(/ +/g, "_");
														send_title = send_title.toLowerCase();
														if (i < 3) {
															return (
																<div className="recent_item" key={i}>
																	<Link
																		className="blog_img"
																		to={{
																			pathname: config.PATH + "" + send_title,
																		}}
																	>
																		<h4>{item.blog_title}</h4>
																	</Link>
																	<h5>{rcreate_date}</h5>
																</div>
															);
														}
													})}
											</div>
										</aside>
									)}
								</div>
							</div>
							<div className="col-md-9 pull-right">
								<div className="blog_listing_inner">
									{list_data &&
										list_data.map(function (item, i) {
											var des = item.blog_description.substr(0, 150) + "...";
											var date = new Date(item.created_at);
											var create_date = date.toDateString().substr(4, 11);
											let send_title = item.blog_title.replace(
												/[^a-zA-Z0-9 ]/g,
												""
											);
											send_title = send_title.replace(/ +/g, "_");
											send_title = send_title.toLowerCase();
											return (
												<div className="blog_item" key={i}>
													<Link
														className="blog_img"
														to={{
															pathname: config.PATH + "" + send_title,
														}}
													>
														<img
															src={
																config.wbImagesEndpoint + "/" + item.blog_image
															}
															alt={item.blog_image_alt}
														/>
													</Link>
													<div className="blog_text">
														<Link
															to={{
																pathname: config.PATH + "" + send_title,
															}}
														>
															<h4>{item.blog_title}</h4>
														</Link>
														<ul>
															<li>
																<a>{create_date}</a>
															</li>
														</ul>
														<p dangerouslySetInnerHTML={{ __html: des }}></p>
														<Link
															className="accom-btn"
															to={{
																pathname: config.PATH + "" + send_title,
															}}
														>
															Read more
														</Link>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
}
export default Blog;
