import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const FacilitiesSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [facilitiesSectionData, setFacilitiesSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const facilitySection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-facility-section-data/' + company_id + '/' + template_id
                    );
                    const result = await facilitySection.json();

                    setFacilitiesSectionData({ "facilities_data": result && result.hotel_facilities, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = facilitiesSectionData && facilitiesSectionData.section_details


    return (


        <div>
            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}



            {facilitiesSectionData && facilitiesSectionData.facilities_data && facilitiesSectionData.facilities_data.length > 0 && <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container margin-top">
                    <div className="row facilitie-margin">
                        <div className="col-md-6">
                            {facilitiesSectionData.facilities_data[0].facilities.map(function (items, id) {
                                if (id % 2 === 0) {
                                    return (
                                        <div className="facility-list" key={id}>
                                            <span className="facility-icon"><i className="fa fa-check-circle-o"></i></span>
                                            <span className="facility-name">{items}</span>
                                        </div>

                                    )
                                }
                            })
                            }

                        </div>
                        <div className="col-md-6">
                            {facilitiesSectionData.facilities_data[0].facilities.map(function (items, id) {
                                if (id % 2 !== 0) {
                                    return (
                                        <div className="facility-list" key={id}>
                                            <span className="facility-icon"><i className="fa fa-check-circle-o"></i></span>
                                            <span className="facility-name">{items}</span>
                                        </div>
                                    )
                                }
                            })
                            }

                        </div>
                    </div>
                </div>
            </section>}

        </div>


    );
}

export default FacilitiesSection;