import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const AwardsSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [awardSectionData, setAwardSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const awardSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-award-section-data/' + company_id + '/' + template_id
                    );
                    const result = await awardSection.json();

                    setAwardSectionData({ "awards": result && result.awards, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = awardSectionData && awardSectionData.section_details

    return (
        <div>

            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


            <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                {awardSectionData && awardSectionData.awards && awardSectionData.awards.awards.length > 0 &&
                    <div className="container container-space margin-top services-margin-top">
                        <div className="row service-row">
                            {awardSectionData.awards.awards.map(function (item, i) {

                                return (
                                    <div className="col-md-3 text-center" key={i}>
                                        <img className="awards-images" src={config.wbImagesEndpoint + '/' + item} alt={awardSectionData.awards.awards_image_alt} />
                                    </div>
                                )
                            })}

                        </div>
                    </div>}
            </section>

        </div>

    );
}


export default AwardsSection;