import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { compose, withProps } from "recompose";
function HotelsPage() {
    var hotel_name_url;
    hotel_name_url = window.location.pathname;
    hotel_name_url = hotel_name_url.replace(config.PATH, "");

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [hotelsPageData, setHotelsPageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let name_hotel;
                    let hotel_id;
                    let all_hotel_rooms = []
                    let room_data = [];
                    let hotel_data;
                    let nearby_data;
                    let facilities_data;
                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-hotel-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.hotel_details) {
                        for (let i = 0; i < result.hotel_details.length; i++) {
                            name_hotel = result.hotel_details[i].hotel_name.replace(/[^a-zA-Z ]/g, "");
                            name_hotel = name_hotel.replace(/ +/g, "_");
                            name_hotel = name_hotel.replace(",", "");
                            name_hotel = name_hotel.toLowerCase();
                            if (hotel_name_url === name_hotel) {
                                hotel_id = result.hotel_details[i].hotel_id
                                hotel_data = result.hotel_details[i]
                            }
                        }
                    }

                    if (result && result.room_details.data) {
                        for (var i = 0; i < result.room_details.data.length; i++) {
                            for (var j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (result && all_hotel_rooms && hotel_id) {
                        for (let i = 0; i < all_hotel_rooms.length; i++) {
                            if (all_hotel_rooms[i].hotel_id === hotel_id) {
                                room_data.push(all_hotel_rooms[i]);
                            }
                        }
                    }

                    if (result && result.nearby_places && hotel_id) {
                        for (let i = 0; i < result.nearby_places.length; i++) {
                            if (result.nearby_places[i].hotel_id === hotel_id) {
                                nearby_data = result.nearby_places[i];
                            }
                        }
                    }

                    if (result && result.hotel_facilities && hotel_id) {
                        for (let i = 0; i < result.hotel_facilities.length; i++) {
                            if (result.hotel_facilities[i].hotel_id === hotel_id) {
                                facilities_data = result.hotel_facilities[i];
                            }
                        }
                    }

                    setHotelsPageData({ "hotel_data": hotel_data, "room_data": room_data, "room_section_data": result && result.room_section_data, "nearby_data": nearby_data, "nearby_section_data": result && result.nearby_section_data, "facilities_data": facilities_data, "facilities_section_data": result && result.facility_section_data, "contact_section_data": result && result.contact_section_data, "sub_menu": result && result.sub_menu })
                    window.$('.carousel').carousel({
                        interval: 5000
                    });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });


    return (
        <div>
            {(hotelsPageData === undefined) ? <div className="loader" style={{ backgroundImage: "url(" + require('../img/preload.gif') + ")" }}></div> : <Header />}
        
            {hotelsPageData && hotelsPageData.hotel_data &&  <Banner bannerdata={hotelsPageData.hotel_data}/>}

            <a id="" className="scrollup show" title="Go To Top" style={{ bottom: "15px" }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}></a>
            {hotelsPageData && hotelsPageData.hotel_data &&  <SliderHotel sliderdata={hotelsPageData.hotel_data} />}
            {hotelsPageData && hotelsPageData.hotel_data && <ContactAlt contactdata={hotelsPageData.hotel_data} contact_section_data={hotelsPageData.contact_section_data} />}
            {hotelsPageData && hotelsPageData.hotel_data && <Map mapdata={hotelsPageData.hotel_data} />}


            {hotelsPageData &&  <Footer />}
        </div>
    )
}


function Map(props) {
    let map_data = props.mapdata
    return (
        <section className="get_contact_area places-nearby">
            <div className="container">
                <div className="row get_contact_inner">
                    <div className="col-md-12">
                        <div className="map_box_inner">
                            {map_data && <MyMapComponent isMarkerShown
                                lat={parseFloat(map_data.latitude)}
                                lng={parseFloat(map_data.longitude)} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}


const MyMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvbN2yXDGSnDIMmUp9JByD39hIsJhDKok&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: props.lat, lng: props.lng }}>
        {props.isMarkerShown && (
            <Marker position={{ lat: props.lat, lng: props.lng }} />
        )}
    </GoogleMap>
));



function ContactAlt(props) {
    
    let contact_section_data = props.contact_section_data
    let contact_data = props.contactdata

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [validname, setvalidname] = useState(true);
    const [validemail, setvalidemail] = useState(true);
    const [validnumber, setvalidnumber] = useState(true);
    const [message, setmessage] = useState('');
    const [subject, setSubject] = useState('');
    const [successmsg, setsuccessmsg] = useState(true)

    let numbers = [];
    let emails = [];
    if (contact_data) {
        numbers = contact_data.mobile.split(',');
        emails = contact_data.email_id.split(',');
    }

    let hotel_id = contact_data && contact_data.hotel_id

    const contactform = () => {

        if (validname === true && validemail === true && validnumber === true && name.length !== 0 && email.length !== 0 && number.length !== 0) {
            fetch(config.apiEndpoint + '/userwb/send-user-query',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": hotel_id,
                        "name": name,
                        "email": email,
                        "mobile": number,
                        "message": message

                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    setsuccessmsg(false)
                })

            fetch(config.apiEndpoint + '/insert-customer-data',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "hotel_id": hotel_id,
                        "user_name": name,
                        "user_email_id": email,
                        "user_contact_number": number,
                        "user_message": message
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                })

        }
        else {
            if (name.length === 0 && email.length === 0 && number.length === 0) {
                setvalidname(false);
                setvalidemail(false);
                setvalidnumber(false);
            }

        }
    }


    const validateName = (name) => {
        setname(name)
        if (name.match(/[A-Za-z]{2,}/)) {
            setvalidname(true)
        }
        else {
            setvalidname(false)

        }

    }

    const validateSubject = (subject) => {
        setSubject(subject)
    }

    const validateEmail = (email) => {
        setemail(email)

        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            setvalidemail(true)
        }
        else {
            setvalidemail(false)
        }

    }
    const validateNumber = (number) => {
        setnumber(number)
        if (number.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)) {
            setvalidnumber(true)
        }
        else {
            setvalidnumber(false)

        }
    }
    const validateMessage = (message) => {
        setmessage(message)
    }

    return (
        <div>
            <section>
                <div className="container container-space margin-top">
                    <div className="row">

                        <div className="col-md-6 col-margin">
                            {contact_section_data && <h1 className="container-heading">{contact_section_data.section_title}</h1>}
                            <div className="contact-detail-wrap">
                                {contact_data && <div className="contact-details">Address:- <span className="contact-color">{contact_data.hotel_address}</span></div>}
                                {numbers.map(function (items, i) {
                                    return (
                                        <div className="contact-details" key={i}>Phone No:- <span className="contact-color"><a href={"tel:" + items}>{items}</a></span></div>)
                                })}

                                {emails.map(function (items, i) {
                                    return (
                                        <div className="contact-details" key={i}>Email:- <span className="contact-color"><a href={"mailto:" + items}>{items}</a></span></div>
                                    )
                                })}

                            </div>


                        </div>

                        <div className="col-md-6 col-margin">
                            <div className="row">
                                <div className="col-md-6">
                                    <div hidden={validname} style={{ color: 'red' }}>Invalid Name</div>
                                    <input className="input-style" placeholder="Full Name *" type="text" value={name} onChange={(e) => validateName(e.target.value)} />
                                </div>

                                <div className="col-md-6">
                                    <div hidden={validemail} style={{ color: 'red' }}>Invalid Email</div>
                                    <input className="input-style" placeholder="Your Email *" type="email" value={email} onChange={(e) => validateEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <input className="input-style" placeholder="Subject *" type="text" value={subject} onChange={(e) => validateSubject(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div hidden={validnumber} style={{ color: 'red' }}>Invalid Number</div>
                                    <input className="input-style" placeholder="Number *" type="text" value={number} onChange={(e) => validateNumber(e.target.value)} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <textarea className="text-area-style contact-textarea" placeholder="Your Message *" row="10" value={message} onChange={(e) => validateMessage(e.target.value)}></textarea>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-btn" onClick={() => contactform()}>
                                        <a >Send Message</a>
                                    </div>
                                    <div hidden={successmsg} style={{ color: 'red', fontSize: 15, fontWeight: 'bold' }}>Submitted Successfully !</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}


function SliderHotel(props) {
    let slider_data = props.sliderdata
    return (
        <div>
        <section>
            <div className="container container-space margin-top">
                <div className="row">
                    <div className="package-slider-wrap">
                        <div id="location-page-carousel" className="carousel package-carousel-slider slide" data-ride="slide" data-interval="false">
                            <div className="carousel-inner location-page">

                            {slider_data && slider_data.image_name && slider_data.image_name.map(function (item, i) {
                                if(item !== null){
                                    return (
                                        <div className={i === 0 ? "item active" : "item"} key={i}>
                                            <img src={config.imageEndpoint + '/' + item.image_name} />
                                            <figcaption>
                                               
                                            </figcaption>
                                        </div>
                                    )
                                }
                                })}
                            </div>

                            <a className="left carousel-control-width carousel-control" href="#location-page-carousel" data-slide="prev">
                                <span className="icon-prev"></span>
                            </a>
                            <a className="right carousel-control-width carousel-control" href="#location-page-carousel" data-slide="next">
                                <span className="icon-next"></span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container container-space margin-top">
                {slider_data && <div className="row">
                    <h1 className="container-heading">{slider_data.hotel_name}</h1>
                    <p className="container-paragraph location-page" dangerouslySetInnerHTML={{ __html: slider_data.hotel_description }}></p>
                </div>}
            </div>
        </section>
        </div>
    )
}

function Banner(props){
    let banner_data = props.bannerdata
    return(

        <section>
            {banner_data && <div className="standard-top-banner digha-bg" style={{ background: "url(" + config.imageEndpoint + '/' + banner_data.image_name[0].image_name + ") no-repeat fixed center center", backgroundSize: "cover" }} >
                <div className="page-title-wrapper">
                    <h1 className="heading__secondary">{banner_data.hotel_name}</h1>
                </div>
            </div>}
        </section>
    )
}

export default HotelsPage;