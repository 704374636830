import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'
import moment from 'moment';

const OfferSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [offerSectionData, setOfferSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let offer_data = []
                    const offerSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-offer-section-data/' + company_id + '/' + template_id
                    );
                    const result = await offerSection.json();

                    if (result && result.default_hotel) {
                        for (let i = 0; i < result.hotel_offers.length; i++) {
                            if (result.hotel_offers[i].hotel_id === result.default_hotel) {
                                offer_data.push(result.hotel_offers[i]);
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < result.hotel_offers.length; i++) {
                            if (result.hotel_offers[i].hotel_id === result.all_hotels_data[0].hotel_id) {
                                offer_data.push(result.hotel_offers[i]);
                            }
                        }
                    }


                    setOfferSectionData({ "offer_data": offer_data, "section_details": result && result.section_details,"hotel_list":result && result.all_hotels });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = offerSectionData && offerSectionData.section_details

    let hotel_id=offerSectionData && offerSectionData.hotel_list && offerSectionData.hotel_list[0].hotel_id
    let iframe
    let toDate = new Date();
    let fromDate = new Date();
    toDate.setDate(fromDate.getDate() + 1);
    let to_date = new Date(moment(toDate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(fromDate).format('MM/DD/YYYY')).getTime();
    let allData = from_date + '|' + to_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + ""
    let encryptData = btoa(allData);
    // iframe = encryptData

    return (
        <div>

            {section_details && <section>
                <div className="container container-space margin-top">
                    <div className="row">
                        <h1 className="container-heading">{section_details.section_title}</h1>
                        <p className="container-paragraph" dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                    </div>
                </div>
            </section>}


        <section className="parallax-bg" style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")"}}>
            <div className="container container-space parallax-margin-top">
                <div className="row">
                    <div className="offer-wrap">
                        {section_details && <div className="offer-big-text">{section_details.section_title}</div>}
                        <div>
                            {/* <Link className="offer-btn"
                                to={{
                                    pathname: config.PATH + 'booknow',
                                    search: '?' + (iframe),
                                }}
                            >Book Now</Link> */}
                            <a className="offer-btn" href={"https://" + companyDetails.subdomain_name}>Book Now</a>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>

    );
}

export default OfferSection;